@font-face {
  font-family: "Bodoni Moda";
  src: url("./asset/fonts/Bodoni_Moda/Bodoni_Moda/static/BodoniModa_9pt-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "darker_grotesque";
  src: url("./asset//fonts/Darker_Grotesque/darker_grotesque/ttf/DarkerGrotesque-Regular.ttf")
    format("truetype");
}

.bodoni {
  font-family: "Bodoni Moda", serif;
}
.home h1 {
  font-family: "Bodoni Moda", serif;
  font-weight: 500;
}
.payment {
  font-family: "darker_grotesque", sans-serif;
}
